<template>
    <div class="flex mx-5 my-5">
        <SideBar :btn-text="''" />
        <div class="w-full overflow-x-auto bg-white rounded-lg shadow-md">
            <h2 class="my-5 font-bold text-center text-2lxl">
                首頁即刻快閃開單背景圖
            </h2>
            <div class="flex justify-center px-5">
                <div class="flex-1 px-5 border-r border-gray-100">
                    <h2
                        class="mb-5 text-base font-medium text-center md:text-xl"
                    >
                        電腦版圖片
                    </h2>
                    <div
                        v-if="!desktopBGImage"
                        class="h-[300px] flex items-center justify-center"
                    >
                        <img
                            v-if="imageData.desktopBG"
                            class="w-full mx-auto"
                            :src="imageData.desktopBG"
                            alt=""
                        />
                        <div v-else class="text-center">未上傳圖片</div>
                    </div>
                    <Upload
                        ref="desktopImgUploadRefDom"
                        :photoImage.sync="desktopBGImage"
                        :photoFile.sync="desktopBGFile"
                        :imageSizes="{ width: [3840], height: [318] }"
                    />
                </div>

                <div class="flex-1 px-5">
                    <h2
                        class="mb-5 text-base font-medium text-center md:text-xl"
                    >
                        手機版圖片
                    </h2>
                    <div
                        v-if="!mobileBGImage"
                        class="h-[300px] flex items-center justify-center"
                    >
                        <img
                            v-if="imageData.mobileBG"
                            class="w-full mx-auto"
                            :src="imageData.mobileBG"
                            alt=""
                        />
                        <div v-else class="text-center">未上傳圖片</div>
                    </div>
                    <Upload
                        ref="mobileImgUploadRefDom"
                        :photoImage.sync="mobileBGImage"
                        :photoFile.sync="mobileBGFile"
                        :imageSizes="{ width: [750], height: [412] }"
                    />
                </div>
            </div>
            <div class="flex justify-center py-5 mt-5">
                <button
                    v-permission="['update']"
                    class="orange-btn-800-lg text-md myDisabled"
                    :disabled="!desktopBGFile && !mobileBGFile"
                    @click="onSubmit()"
                >
                    更新
                </button>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 左側選單
import SideBar from "../components/SideBar.vue";
// 導入自定義彈窗組件
import myDialog from "@/components/Dialog.vue";

// 圖片上傳組件
import Upload from "@/components/ImageUpload.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const showDialog = ref(false);
  const desktopImgUploadRefDom = ref(null);
  // 桌機版圖片base64
  const desktopBGImage = ref(null);
  // 桌機版圖片檔案
  const desktopBGFile = ref(null);
  // 桌機版圖片base64
  const mobileBGImage = ref(null);
  // 桌機版圖片檔案
  const mobileBGFile = ref(null);
  const mobileImgUploadRefDom = ref(null);

  // 已上傳圖片
  const imageData = ref({
    desktopBG: "",
    mobileBG: ""
  });

  /**
   * 新增按鈕
   */
  function openDialog() {
    showDialog.value = true;
    // router.push({ name: "add_user_experience" });
  }

  /**
   * 關閉按鈕
   */
  function closeDialog() {
    showDialog.value = false;
  }
  async function getClientImg() {
    try {
      const {
        data
      } = await proxy.$api.GetClientImgApi();
      imageData.value.desktopBG = data.background.wdhimageurl;
      imageData.value.mobileBG = data.background.wmhimageurl;
    } catch (err) {
      console.log("GetClientImgApi =>", err);
      proxy.$message({
        type: "error",
        message: "取得圖片失敗"
      });
    }
  }
  async function onSubmit() {
    const result = new FormData();
    if (desktopBGFile.value) {
      result.append("wdhimage", desktopBGFile.value);
    }
    if (mobileBGFile.value) {
      result.append("wmhimage", mobileBGFile.value);
    }
    try {
      await proxy.$api.UploadClientImgApi(result);
      desktopBGImage.value = null;
      desktopBGFile.value = null;
      mobileBGImage.value = null;
      mobileBGFile.value = null;
      mobileImgUploadRefDom.value.reset();
      desktopImgUploadRefDom.value.reset();
      await getClientImg();
      proxy.$message({
        type: "success",
        message: "上傳成功"
      });
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "上傳圖片失敗"
      });
    }
  }
  onMounted(async () => {
    await getClientImg();
  });
  return {
    desktopImgUploadRefDom,
    desktopBGImage,
    desktopBGFile,
    mobileBGImage,
    mobileBGFile,
    mobileImgUploadRefDom,
    imageData,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  SideBar,
  Upload
}, __sfc_main.components);
export default __sfc_main;
</script>
